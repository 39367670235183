import React, { useState, useEffect, useContext } from "react"
import { Helmet } from "react-helmet-async"

import jiggswapApi from "api/jiggswap"
import { AuthContext } from "context/AuthContext"

import PuzzleCardList from "components/puzzles/PuzzleCardList"
import { Link } from "gatsby"

const PuzzlesIndex = () => {
  const {
    state: { username, unloaded },
  } = useContext(AuthContext)

  const [puzzles, setPuzzles] = useState([])

  useEffect(() => {
    if (!unloaded) {
      async function fetchPuzzles() {
        let query = ""
        if (username) {
          query = `ignoreOwner=${username}`
        }

        let response = await jiggswapApi.get(`/puzzles?${query}`)
        setPuzzles(response.data)
      }

      fetchPuzzles()
    }
  }, [unloaded, username])

  return (
    <>
      <Helmet>
        <title>{"Puzzles - Jiggswap"}</title>
        <meta property="og:title" content={"Puzzles Available for Trade - JiggSwap"} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.jiggswap.com/puzzles" />
        <meta property="og:image" content={"https://www.jiggswap.com/logo.png"} />
        <meta property="og:description" content={"JiggSwap lets you trade your jigsaw puzzles with other puzzle lovers."} />
        <meta name="description" content="Trade your jigsaw puzzles with other puzzle lovers."></meta>
        <meta http-equiv="content-language" content="en-us"></meta>
      </Helmet>
      <div className="site-content">
        <div className="is-size-2 has-text-centered">Puzzles Available For Trade</div>
        <hr />

        <div className="has-text-centered">
          <>
            <Link to="/puzzles/add" className="button is-primary">
              <i className="fas fa-plus mr-1"></i>Add A Puzzle
            </Link>
          </>
        </div>
        <hr />
        <PuzzleCardList puzzles={puzzles} />
      </div>
    </>
  )
}

export default PuzzlesIndex
